import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import * as PropTypes from 'prop-types'

import {css} from '@emotion/core'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Grid from 'src/components/Grid'

import { colors, typography, animations, mq, util } from 'src/styles'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import Image from 'src/components/Image'
import LogoWing from 'src/assets/images/wing-logo.svg'

const padTop = (attr = 'padding-top', additionalHeight = 0) => util.responsiveStyles(attr, (140 + additionalHeight), (96 + additionalHeight), (96 + additionalHeight), (60 + additionalHeight))

const propTypes = {
  data: PropTypes.object.isRequired,
}

const BlogWrapper = styled.div`
  ${ util.responsiveStyles('padding-bottom', 182, 102, 66, 52) }
  .gatsby-image-wrapper {
    background: ${ colors.lightGrey }
  }
`

const MastHead = styled.div`
  padding: 20px 0 0;
  border-top: 2px solid ${ colors.textColor };

  ${ mq.largeAndUp } {
    margin: 0 30px;
  }
`

const MastHeadStamp = styled.div`
  border: 2px solid ${ colors.textColor };
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    ${ typography.h3Alt } 
    ${ util.responsiveStyles('font-size', 28, 22, 20, 18) }

    text-align: center;
    max-width: 10em;
    margin: 0 auto;
  }
  
  ${ mq.largeAndBelow } {
    display: none !important;
  }
`

const LogoWingWrapper = styled.div`
  align-self: center;
  text-align: center;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  svg {
    max-width: 100%;
    display: block;
  }
`

const MastHeadInfo = styled.div`
  border-left: 2px solid ${ colors.textColor };
  display: flex;
  flex-direction: column;
  ${ util.responsiveStyles('padding-left', 20, 18, 16, 10) }
  align-items: justify;
  justify-content: center;

  h3 {
    ${ typography.h5Alt }
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    margin: 0 0 .5em;
    text-align: justify;
  }

  ${ mq.largeAndBelow } {
    display: none !important;
  }
`

const MastHeadFooter = styled.div`
  margin-top: 20px;
  border-top: 2px solid ${ colors.textColor };
  border-bottom: 2px solid ${ colors.textColor };

  span {
    ${ typography.h5Alt }
    text-transform: uppercase;
    line-height: 1;
    display: block;
    padding: .6em 0 .4em;
  }

  span:nth-of-type(1) {
    text-align: left;
  }
  span:nth-of-type(2) {
    text-align: center;
    ${ mq.mediumAndBelow } {
      text-align: right;
    }
  }
  span:nth-of-type(3) {
    text-align: right;
    ${ mq.mediumAndBelow } {
      display: none !important;
    }
  }
`

const BlogPage = styled.div`
  ${ padTop() }
  background-image: url(${ ({ backgroundImage }) => backgroundImage });
  background-size: 400px 400px;
  background-position: center top;
`

var dayjs = require('dayjs')
//import dayjs from 'dayjs' // ES 2015
// dayjs().format()

var now = dayjs()
var nowFormatted = now.format("dddd, MMMM D YYYY")
// console.log(now)

class Blog extends React.Component {
  render () {
    const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
    const blog = this.props.data.allContentfulBlog.edges[0].node
    const bgTexture = this.props.data.bgTexture.publicURL
    const { sections } = blog
    const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
    const seo = blog.seo
    let articleIndex = 0
    let padded = false

    // console.log(blog)

    return (
      <Fragment >
        <SEO
          title={blog.displayName}
          description={seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo.keywords}
          shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
        />
        <Header
          bannerText={site && site.bannerText}
          bannerColor={site && site.bannerColor}
          navigation={site && site.navigation}
          placeholder={false}
        />

        <Drawer />
        <ResponsiveComponent
          small={
            <MobileMenu
              navLinks={site && site.navigation}
            />
          }
          large={<span />}
        />
        
        <BlogPage backgroundImage={bgTexture}>
          <Grid small='1 [12] 1' large='[1]'>
            <MastHead>
              <Grid
                small='[1]'
                medium='1 [10] 1'
                large='[7] 1 [10] 1 [7]'
                larger='[6] 2 [8] 2 [6]'
              >
                <MastHeadStamp>
                  <h2>
                    American Made. Quality, Traceability and Sustainability
                  </h2>
                </MastHeadStamp>
                
                <LogoWingWrapper>
                  <LogoWing />
                </LogoWingWrapper>

                <MastHeadInfo>
                  <h3>EST. 2020</h3>
                  <p css={css`${ typography.bodySmall }`}>
                    Shifting menswear to a more sophisticated look, while creating the most transparent and ethical supply chain possible. Bringing you the latest news and inspiration for our brand.
                  </p>
                </MastHeadInfo>
                  
              </Grid>
              <MastHeadFooter>
                <Grid
                  small='[7] [7]'
                  medium='[7] [7]'
                  large='[7] [12] [7]'
                >
                  <span>The Post by YONY</span>
                  <span>
                    { nowFormatted }
                  </span>
                  <span>Los Angeles, CA</span>
                </Grid>
              </MastHeadFooter>
            </MastHead>
          </Grid>
          

          <BlogWrapper>
            {sections && sections.map((section, index) => {
              const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
              const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
              const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
              const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
              const lastSection = sections.length === index + 1

              section.theme = 'bgColor'

              if (section.__typename === 'ContentfulArticle') articleIndex += 1
              padded = !padded

              // console.log(section)

              return (
                <ComponentRenderer
                  prevTheme={prevFullWidth ? false : prevTheme}
                  nextTheme={nextFullWidth ? false : nextTheme}
                  isFirstSection={index === 0}
                  isLastSection={lastSection}
                  key={`${ section.id }_${ index }`}
                  item={section}
                  index={index}
                  articleIndex={articleIndex}
                  padded={padded}
                />
              )
            })}
          </BlogWrapper>
        </BlogPage>

        
        <Footer {...site} footerTheme='green' />
      </Fragment>
    )
  }
}

Blog.propTypes = propTypes

export const blogQuery = graphql`
  query($id: String!) {
    bgTexture: file(relativePath:{eq: "images/bg-texture.png"}) {
      publicURL
    }
		allContentfulSiteSettings {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulBlog(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					displayName
					slug
					sections {
            ...Image
						...Article
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default Blog
